<template>
<v-row>
  <v-col cols="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                          v => this.$validators.string.greaterThanOrEquals(v, 1),
                          v => this.$validators.string.lessThanOrEquals(v, 150)

                  ]"
                  v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12">
    <v-text-field dense
        label="* Valor"
        prefix="R$"
        :rules="[
            this.$validators.number.required,
            v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]"
        v-model="value.valor" type="number"/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "EventoForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>